<template>
  <BaseDialog :model-value="modal.status" size-class="!max-w-xl" @update:model-value="close">
    <template #header>
      <div class="flex items-center text-sm font-medium">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="mr-2 h-6 w-6 text-red-900"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
        <span> Delete {{ watchlist?.name }} </span>
      </div>
    </template>
    <template #default>
      <div class="flex items-center text-sm">
        <template v-if="alerts.length">
          There are {{ alerts?.length }} active alerts on the {{ watchlist?.type }}. Please confirm one of the actions
          to continue.
        </template>
        <template v-else> Please confirm you want to delete this {{ watchlist?.type }}? </template>
      </div>
    </template>
    <template #footer>
      <div v-if="alerts.length" class="mt-5 flex w-full justify-between">
        <div class="flex items-end space-x-2">
          <BaseButton type="danger" :disabled="buttonBusy" @press="deleteWatchlist('delete')"
            >Delete All Alerts</BaseButton
          >
          <BaseButton type="secondary" @press="deleteWatchlist('pause')">Pause All Alerts</BaseButton>
        </div>
        <div class="flex items-end justify-end space-x-2">
          <BaseButton :disabled="buttonBusy" @press="deleteWatchlist()">Continue</BaseButton>
        </div>
      </div>
      <div v-else class="flex w-full items-end justify-end space-x-2">
        <BaseButton type="secondary" @press="close">Cancel</BaseButton>
        <BaseButton type="danger" :disabled="buttonBusy" @press="deleteWatchlist()">Proceed</BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>
<script setup>
import { computed, watch, ref } from 'vue';
import useHttp from '@/composeables/http';
import { useStore } from 'vuex';
import { useWatchlistStore } from '@/stores/watchlists';
const $http = useHttp();
const $store = useStore();
const $watchlistStore = useWatchlistStore();

//MODAL
const modal = computed(() => {
  return $store.state.watchlistAlertsModal;
});

const watchlistId = computed(() => {
  return modal.value.payload?.watchlistId;
});

const close = () => {
  $store.commit('setWatchlistAlertsModal', { status: false, payload: {} });
};

//WATCHLIST
const watchlist = ref({});
const alerts = ref([]);

watch(watchlistId, async () => {
  if (watchlistId.value) {
    watchlist.value = $watchlistStore.getWatchlistFromStore(watchlistId.value);
    watchlist.value = {
      ...watchlist.value,
      type: watchlist.value.watchlist_type == 'onchain_addresses' ? 'folder' : 'watchlist'
    };
    const response = await $http.get('/alerts', { params: { watchlist: watchlistId.value } });
    alerts.value = response.data;
  }
});

async function deleteWatchlist(action = null) {
  await $watchlistStore.deleteWatchlist(watchlist.value.id, action);
  close();
}
</script>
